import { BaseHistory } from '@models/dashboard'

interface Response {
  getHistoryEvent: (keywords: string[]) => BaseHistory | null
}

export const useHistory = (history: BaseHistory[]): Response => {
  const getHistoryEvent = (keywords: string[]) =>
    history.find(historyEntry => keywords.includes(historyEntry.keyword)) || null

  return {
    getHistoryEvent,
  }
}
