import * as React from 'react'
import classnames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { ContentNote } from '@models/dashboard'
import { formatCreatedBy } from '@helpers/utils'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'

interface Props {
  note: ContentNote
  className?: string
}

export const ContentNotesCardContentMessage = ({ note, className }: Props): React.ReactNode => (
  <div
    className={classnames('reservation-details__notes-box', className, { 'opacity-5': note.is_removed })}
    data-testid="notes-card"
  >
    <p className={classnames('text-pre-wrap', { 'text-strikethrough': note.is_removed })}>{note.content}</p>
    {(note.images ?? []).map(noteImage => (
      <img key={noteImage.id} src={noteImage.image} alt="Zdjęcie do notatki" style={{ maxHeight: 250 }} />
    ))}
    <div className="d-flex font-11">
      {note.pinned_datetime && (
        <i className="uil-bookmark text-gold-light font-14 lh-0 align-self-start" title="Notatka priorytetowa" />
      )}
      <div className="ml-auto">
        <IconWithText
          icon="uil-calendar-alt font-12"
          text={`${toDefaultDateFormat(note.created)}, ${toDefaultTimeFormat(note.created)}`}
          wrapperClassNames="mr-2"
        />
        <IconWithText icon="uil-user font-12" text={formatCreatedBy(note.created_by)} />
      </div>
    </div>
  </div>
)
