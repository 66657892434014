import { ReceptionBookingDetails } from '@models/reception'
import { useHistory } from '@components/hooks/use-history'

export type ReservationStatusLabelType = 'initial' | 'confirmed' | 'checked-in' | 'finished' | ''

interface Response {
  status: ReservationStatusLabelType
  getStatusChangeDate: (status: ReservationStatusLabelType) => string | null
}

export const useReservationStatusLabelData = (booking: ReceptionBookingDetails): Response => {
  const { getHistoryEvent } = useHistory(booking.history)

  const getStatus = () => {
    if (booking.checked_in && booking.status === 'confirmed') return 'checked-in'

    switch (booking.status) {
      case 'system':
      case 'fake':
      case 'blocked':
      case 'unfinished':
        return ''
      case 'initial':
      case 'initial_waiting':
        return 'initial'
      case 'confirmed':
        return 'confirmed'
      case 'confirmed_canceled':
      case 'initial_cancelled':
      case 'unfinished_canceled':
      case 'close':
        return 'finished'
      default:
        return ''
    }
  }

  const getStatusChangeDate = (status: ReservationStatusLabelType) => {
    if (status === 'initial') return booking.created_at
    if (status === 'confirmed')
      return getHistoryEvent(['confirmed_user', 'confirmed_pay', 'confirmed_source'])?.created_at ?? null
    if (status === 'checked-in') return booking.checked_in_date
    if (status === 'finished') {
      if (booking.status === 'confirmed_canceled') return getHistoryEvent(['canceled', 'outdated'])?.created_at ?? null
      if (booking.status === 'initial_cancelled') return getHistoryEvent(['canceled', 'outdated'])?.created_at ?? null
      return booking.close_datetime
    }

    return null
  }

  return {
    status: getStatus(),
    getStatusChangeDate,
  }
}
