import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { useReservationStatusLabelData } from '@modules/reservations/details/base-data/booking/status-label/use-status-data'
import { ReservationStatusLabel } from '@modules/reservations/details/base-data/booking/status-label/reservation-status-label'

const StatusesToShow = ['initial', 'confirmed', 'checked-in', 'finished'] as const

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationStatus = ({ booking }: Props): React.ReactNode => {
  const { status, getStatusChangeDate } = useReservationStatusLabelData(booking)

  return (
    <div className="reservation-details__label__wrapper">
      {StatusesToShow.map(statusToShow => (
        <ReservationStatusLabel
          key={statusToShow}
          status={statusToShow}
          date={getStatusChangeDate(statusToShow)}
          booking={booking}
          isActive={statusToShow === status}
        />
      ))}
    </div>
  )
}
