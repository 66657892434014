import * as React from 'react'
import classNames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { EmailNotification, NotificationKind, SMSNotification } from '@models/notifications'
import { useModal } from '@components/modals/use-modal'
import { useHoverMenu } from '@components/hooks/use-hover-menu'
import { NotificationHoverMenu } from '@components/notification-box/notification-hover-menu'
import { formatCreatedBy } from '@helpers/utils'

interface Props {
  notification: EmailNotification | SMSNotification
  icon: string
  title: string
  notificationKind: NotificationKind
}

export const NotificationMessage = ({ notification, icon, title, notificationKind }: Props): JSX.Element => {
  const { handleMouseEnter, handleMouseLeave, isMenuVisible } = useHoverMenu(!notification.send_to)

  const [showPreviewModal] = useModal('NotificationPreviewModal', {
    notification,
    previewUrl: 'urls' in notification ? notification.urls.preview : '',
    notificationKind,
    notificationId: notification.id,
  })

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="position-relative overflow-hidden pb-2"
    >
      <div className="reservation-details__notifications-box">
        <i className={classNames('d-block font-16 mr-2', icon)} />
        <div className="w-100">
          <span className="fw-semi-bold">{title}</span>

          {'urls' in notification && notification.urls.preview && (
            <button
              className="border-0 shadow-none text-default p-0 my-2 bg-transparent d-block"
              onClick={showPreviewModal}
            >
              <IconWithText icon="uil-eye" text="Podgląd wiadomości" />
            </button>
          )}

          {'content' in notification && <p className="font-11 text-default my-1">{notification.content}</p>}

          <div className="d-flex justify-content-end">
            <div className="ml-auto">
              <IconWithText
                icon="uil-calendar-alt"
                text={`${toDefaultDateFormat(notification.created)}, ${toDefaultTimeFormat(notification.created)}`}
                wrapperClassNames="mr-2 font-11"
              />
              {notification.user && (
                <IconWithText icon="uil-user font-12" text={formatCreatedBy(notification.user)} textClass="font-11" />
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationHoverMenu isVisible={isMenuVisible} notification={notification} />
    </div>
  )
}
