import * as React from 'react'
import { StepGuestsWalletScopeHeaderRow } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scope-header-row'
import { StepGuestsWalletScopeRow } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scope-row'
import { useFormContext, useWatch } from 'react-hook-form'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { GuestWalletScope } from '@models/booking'

export interface FormScope {
  title: string
  key: GuestWalletScope
  scopeFormName: FieldPath<StepGuestsGuestFormInputs>
  limitFormName?: FieldPath<StepGuestsGuestFormInputs>
}

export type ExpandableScope = 'gastro' | 'vendingMachines' | 'shop' | 'parkAndFun'

interface Props {
  title: string
  expandableScope: ExpandableScope
  isExpanded: boolean
  toggleIsExpanded: () => void
  formScopes: FormScope[]
  totalScopesAmount: number
}

export const StepGuestsWalletScopesItem = ({
  title,
  expandableScope,
  formScopes,
  toggleIsExpanded,
  isExpanded,
  totalScopesAmount,
}: Props): JSX.Element => {
  const { control, formState, setError } = useFormContext<StepGuestsGuestFormInputs>()
  const limits = useWatch({ control, name: 'wallet_limits' })

  const formPaths = formScopes.map(({ scopeFormName }) => scopeFormName)
  const scopes = useWatch({ control, name: formPaths })

  React.useEffect(() => {
    const walletErrors = formState.errors.wallet_limits
    if (!Array.isArray(walletErrors) || !walletErrors.length) return

    const enabledWallets = Object.values(limits).filter(limit => limit.enabled)

    walletErrors.forEach((walletError, index) => {
      const formWallet = enabledWallets[index]
      if (!formWallet || formWallet.scope === null) return

      setError(`wallet_limits.${formWallet.scope}.limit`, walletError.limit)
    })
  }, [formState.errors.wallet_limits])

  return (
    <StepGuestsWalletScopeHeaderRow
      title={title}
      scope={expandableScope}
      isExpanded={isExpanded}
      toggleExpanded={toggleIsExpanded}
      totalScopesAmount={totalScopesAmount}
      selectedScopesAmount={scopes.filter(Boolean).length}
      scopePaths={formPaths}
    >
      <StepGuestsWalletScopeRow scopes={formScopes} />
    </StepGuestsWalletScopeHeaderRow>
  )
}
