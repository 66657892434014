import * as React from 'react'
import { Apartment, ApartmentBookingPromocode, ApartmentDamage, ApartmentItem } from '@models/apartment'
import {
  BookingDay,
  BookingPaymentSource,
  BookingPaymentType,
  BookingStatus,
  ParkingKind,
  ReceptionBookingDetailsImprovement,
  ReceptionBookingDetailsUrls,
  ReceptionBookingGuest,
  ReceptionBookingServiceRoomData,
  ReceptionBookingUrls,
} from '@models/booking'
import { BaseHistory, ContentNote } from '@models/dashboard'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { EmailNotification, SMSNotification } from '@models/notifications'
import { BaseObj, BaseUrls } from '@models/base'
import { ManualDepositTransferStatus } from '@modules/accounting/refund/models'
import { Improvement } from '@models/reservation-create'
import { SmsTemplate } from '@modules/cms/models'
import { SubscriptionDetails } from '@modules/subscription/models'

export type ReceptionSmsTemplate = Pick<SmsTemplate, 'content_pl' | 'id' | 'name'>

export interface ReceptionAppData {
  apartment_damages: ApartmentDamage[]
  apartment_items: ApartmentItem[]
  discount_notes: DiscountNote[]
  discounts: string[]
  status: 'unknown' | 'ready' | 'loading'
  sms_templates: ReceptionSmsTemplate[]
}

export interface ReceptionBookingsAggregationState {
  to_checkin: number
  to_checkout: number
  complete_checkin: number
}

export enum ReceptionCheckType {
  TO_CHECKIN = 'to-checkin',
  TO_CHECKOUT = 'to-checkout',
  COMPLETE_CHECKIN = 'complete-checkin',
}

export enum BookingExtendedStayKind {
  TWELVE_O_CLOCK = 'TWELVE_O_CLOCK',
  EIGHT_O_CLOCK = 'EIGHT_O_CLOCK',
  DAYS = 'DAYS',
}

export interface BookingExtendedStayOption {
  original_price_brutto: string
  name: string
  hour: string
  kind: BookingExtendedStayKind
  available: boolean
  price_brutto: number
  available_date_to: string | null
}

interface BookingExtendedStayDaysOptionPriceDetails {
  apartment_price: string
  climatic_tax: string
  improvements_price: string
  service_charge: string
  apartment_discount: string
  total: string
}

export interface BookingExtendedStayDaysOption extends BookingExtendedStayOption {
  price_details: BookingExtendedStayDaysOptionPriceDetails
}

export interface DiscountNote {
  id: string
  name: string
}

export interface ReceptionEmailNotifications {
  label: string
  key: string
  content: string
  context: ReceptionCheckType[]
}

export interface ReceptionSmsNotifications {
  label: string
  content: string
  key: string
  context: ReceptionCheckType[]
}

export interface ReceptionReminder {
  children: React.ReactNode
  id: string
  type?: 'success' | 'info' | 'danger'
  isClosable?: boolean
}

export type ReceptionBandState = 'None' | 'PROCESSING' | 'RELEASED'

export interface ReceptionBand {
  id: number
  datetime_from: string | null
  datetime_to: string | null
  external_id: string | null
  guest_id: number
  state: ReceptionBandState
  state_display: string
  urls: BaseUrls
}

export type BandReaderResultType = 'CANCEL' | 'ERROR' | 'SUCCESS' | 'NOT_FOUND'

type BandReaderStatus = 'NEW' | 'PROCESSING' | 'COMPLETED'

export interface BandReaderResult {
  uuid: string | undefined | null
  booking: ReceptionBooking
  booking_guests: ReceptionBookingGuest[]
  result: BandReaderResultType
  status: BandReaderStatus
  error_details: string
}

export type ReceptionAction =
  | 'check-in-started'
  | 'check-in-cancel'
  | 'check-in-finished'
  | 'check-out-started'
  | 'check-out-cancel'
  | 'check-out-finished'
  | ''

export interface ReceptionBookingDamage {
  id: number
  name: string
  price_brutto: string
  tags: string[]
  urls: BaseUrls
}

export interface ReceptionBookingData {
  group_symfonia_handel_position: boolean
  utm: any
  created_in?: 'reservation-app-new' | 'dashboard-app'
}

export interface ReceptionBooking {
  accommodation_type_id: number
  adults: number
  animals: number
  apartment: Apartment
  arrival_time: string
  bands_number: string[]
  can_extend_stay: boolean
  canceled_by_id: number | null
  car_register_numbers: string[]
  checked_in: boolean
  checked_in_date: string | null
  checked_in_online: boolean
  checked_out_online: boolean
  children: number
  climatic_tax_brutto: string
  close_datetime: string | null
  closed_by: string | null
  created_at: string
  created_by_id: number | null
  data: ReceptionBookingData
  date_from: string
  date_to: string
  days_count: number
  declared_arrival_time: string | null
  departure_time: string
  deposit: string
  deposit_sources: BookingPaymentSource[]
  discount: number
  discount_kind: string
  email: string
  email_notifications_enabled: boolean
  extended_stay: boolean
  extended_stay_type?: BookingExtendedStayKind
  feeding: 'breakfast' | 'breakfast_with_dinner' | 'dinner' | 'none' | null
  final_brutto: string
  guests: ReceptionBookingGuest[]
  has_notes: boolean
  has_package_vip: boolean
  has_tourist_voucher: boolean
  id: number
  improvements: ReceptionBookingDetailsImprovement[]
  is_package_reservation: boolean
  is_subscription_reservation: boolean
  name: string
  notes: ContentNote[]
  phone: string
  reception_action?: ReceptionAction
  reception_user?: string | undefined
  reservation_number: string
  resort_id: number
  rest_to_pay: string
  selected_apartment: number | null
  sms_notifications_enabled: boolean
  status: BookingStatus
  status_display: string
  urls: ReceptionBookingUrls
}

export interface ReceptionBookingDetailsImprovementDetails extends ReceptionBookingDetailsImprovement {
  is_paid_by_client_wallet: boolean
  urls: BaseUrls
}

export interface BookingGuestFeedingOption {
  date: string
  guest_id: number
  breakfast: boolean
  dinner: boolean
  id: number
  is_dinner_change_allowed: boolean
  is_breakfast_change_allowed: boolean
}

export interface ReceptionBookingFeedingAvailabilityPrices {
  breakfast_price_brutto: string
  dinner_price_brutto: string
  price_brutto_hb: string
}

export interface ReceptionBookingFeedingAvailability {
  adult: ReceptionBookingFeedingAvailabilityPrices | null
  child: ReceptionBookingFeedingAvailabilityPrices | null
  children_3_4: ReceptionBookingFeedingAvailabilityPrices | null
  children_5_12: ReceptionBookingFeedingAvailabilityPrices | null
  baby: ReceptionBookingFeedingAvailabilityPrices | null
}

export interface ReceptionBookingGroup {
  id: number
  name: string
  urls: BaseUrls
}

export interface BookingCar {
  id: number
  register_number: string
  group_id: number | null
  guest_id: number | null
  urls: BaseUrls
  editMode: boolean
  kind: ParkingKind
}

export interface ReservationPrices {
  feeding_brutto: string
  final_brutto: string
  improvement_brutto: string
  improvements: ReceptionBookingDetailsImprovementDetails[]
  not_in_improvements_price: string
  residence_brutto: string
  residence_brutto_before_discount: string
}

export type BookingSource =
  | 'strona'
  | 'booking'
  | 'booking_non_returnable'
  | 'TUI'
  | 'Novasol'
  | 'Interhome'
  | 'Eurotours'
  | 'Neckerman'
  | 'Eholiday'
  | 'TRIO'
  | 'Expedia'
  | 'HRS'
  | 'Travelist'
  | 'Travel Netto'
  | 'ITB Berlin'
  | 'pracownik'
  | 'Mybenefit'
  | 'CALL_CENTER'
  | 'BOARD_MEMBER'

export interface BookingSmartWatchAssignment {
  id: number
  booking_improvement_id: number
  date_from: string
  date_to: string
  booking_id: number
  uid: string
  urls: BaseUrls
}

export interface ReceptionBookingDepositMoveTo {
  reservation_number: string
  id: number
}

export interface BookingDocument {
  id: number
  kind_display: string
  file_url: string
}

export type ReceptionBookingCheckInDocumentKeyword =
  | 'privacy_policy_rules'
  | 'stay_card'
  | 'parking_card'
  | 'tourist_voucher'
  | 'covid'

export interface ReceptionBookingCheckInDocument {
  keyword: ReceptionBookingCheckInDocumentKeyword
  name: string
  required_signature: boolean
  url: string | null
}

export type ReceptionBookingKind = 'employee' | 'standard' | 'booking' | 'cafeteria' | 'event' | 'other'

export interface PriceItem<T> {
  items: T[]
  total_price_brutto: string
}

export interface ExtraCharge {
  created: string
  id: number
  name: string
  price_brutto: string
}
export interface BasePaymentSummary {
  deposit: string
  installment: string
}

interface TimelyPaymentSummary extends BasePaymentSummary {
  required_date: string
}

interface RestPaymentSummary extends TimelyPaymentSummary {
  required_date_deposit: string
}

export interface PaymentSummary {
  paid: BasePaymentSummary
  required: TimelyPaymentSummary
  rest: RestPaymentSummary
}

interface ReceptionBookingDetailsPricesResidence {
  total_price_brutto: string
}

export interface BookingFeeding {
  guest_id: number
  name: string
  price_brutto: string
}

export interface BookingDiscount {
  name: string
  price_brutto: string
}

export interface BookingTouristVoucher {
  name: string
  payment_code: string
  price_brutto: string
}

export interface BookingClimatic {
  name: string
  price_brutto: string
  single_price_brutto: string
}

export interface ReceptionBookingDetailsPrices {
  deposit_amount: string
  deposit_charge: string
  deposit_payments_total_amount: string
  extra_charges: PriceItem<ExtraCharge>
  improvements: PriceItem<Improvement>
  payments_summary: PaymentSummary
  single_service_price_brutto_from: string
  stay_charge: string
  stay_charge_without_climatic: string
  warranty_price_brutto: string
  service_charge_brutto: string
  residence: ReceptionBookingDetailsPricesResidence
  feeding: PriceItem<BookingFeeding>
  discounts: PriceItem<BookingDiscount> | null
  tourist_vouchers: PriceItem<BookingTouristVoucher> | null
  climatic: PriceItem<BookingClimatic>
}

export interface ReceptionBookingOnlineCheckInData {
  id: number
  deposit_return_account_number: string
  deposit_return_address: string
  deposit_return_name: string
  deposit_return_postcode: string
  deposit_return_city: string
}

export interface BookingExtraCharge {
  id: number
  created_at: string
  name: string
  author: string
  author_display: string
  price_brutto: string
  urls: BaseUrls
}

interface BookingTouristVoucherUrls extends BaseUrls {
  confirm: string
}

export interface BookingTouristVoucher {
  id: number
  amount: string
  code: string
  payment_code: string
  created_by: number
  created_by_display: string
  is_confirmed: boolean
  confirmation_date: string
  urls: BookingTouristVoucherUrls
}

export interface ReceptionBookingLoadNoteItem {
  id: number
  name: string
  amount: number
  price_brutto: string
  total_price_brutto: string
}

export interface ReceptionBookingLoadNote extends BaseUrls {
  note_items: string
}

interface ReceptionBookingLoadNoteUrls extends BaseUrls {
  admin: string
  print: string
}

export interface ReceptionBookingLoadNote {
  created_by: string
  created: string
  description: string
  hide: boolean
  id: number
  price_brutto: string
  source: 'cash' | 'creditcard'
  urls: ReceptionBookingLoadNoteUrls
}

export type ReceptionBookingDetailsCartItemKind = 'feeding'

export interface ReceptionBookingDetailsCartItem {
  id: 2
  name: string
  kind: ReceptionBookingDetailsCartItemKind
  is_paid: boolean
  price_brutto: string
  price_brutto_without_discount: string
  booking_guest: number | null
  urls: {}
}

export interface ReceptionBookingCartUrls {
  clear: string
  finalize: string
  payment_card: string
  payment_cash: string
  payment_deposit: string
  payment_wallet: string
  in_progress: string
}

type ReceptionBookingDetailsCartPaymentSource = 'cash'

export interface ReceptionBookingDetailsCartPayment {
  id: number
  created_at: string
  user: string
  source: ReceptionBookingDetailsCartPaymentSource
  source_display: string
  description: string
  amount: string
}

export type ReceptionBookingDetailsCartAvailablePaymentMethod = 'cash' | 'creditcard' | 'deposit' | 'wallet'

export interface ReceptionBookingDetailsCart {
  id: number
  items: ReceptionBookingDetailsCartItem[]
  payments: ReceptionBookingDetailsCartPayment[]
  available_payment_methods: ReceptionBookingDetailsCartAvailablePaymentMethod[]
  urls: ReceptionBookingCartUrls
}

export interface ReceptionBookingDetailsCartItemKindContent {
  paid: number
  pending: number
}

export interface ReceptionBookingDetails extends ReceptionBooking {
  apartment_id: number
  auto_cancel_after: string | null
  auto_notifications_send: boolean
  bands: ReceptionBand[]
  booking_documents: BookingDocument[]
  booking_signature_url: string
  can_transfer_deposit: boolean
  cars: BookingCar[]
  cart: Record<ReceptionBookingDetailsCartItemKind, ReceptionBookingDetailsCartItemKindContent> | null
  check_in_documents: ReceptionBookingCheckInDocument[]
  city: string
  client_id: number
  climatic_tax_brutto: string
  country: string
  created_at: string
  created_by_id: number | null
  damages: ReceptionBookingDamage[]
  damages_brutto: string
  days: BookingDay[]
  deposit_amount: string
  deposit_move_to: ReceptionBookingDepositMoveTo | null
  deposit_to_return: string
  deposit_transfer_required: boolean
  deposit_transfer_status: ManualDepositTransferStatus
  disable_auto_cancel: boolean
  disable_auto_cancel_date: string | null
  discount_by: string
  discount_feeding: number
  discount_feeding_by: string | null
  discount_note: string
  email_notifications: EmailNotification[]
  email_notifications_enabled: boolean
  extracharges: BookingExtraCharge[]
  feeding_options: BookingGuestFeedingOption[]
  feeding_price_brutto: string
  feeding_price_list_date: string | null
  feedings_2_0_brutto: string
  groups: ReceptionBookingGroup[]
  guests: ReceptionBookingGuest[]
  has_returnable_deposit: boolean
  history: BaseHistory[]
  improvements: ReceptionBookingDetailsImprovementDetails[]
  improvements_brutto: string
  invoice_city: string
  invoice_company: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  is_paid: boolean
  kind: ReceptionBookingKind
  last_installment_payment_date: string
  last_synchronization: string | null
  media_brutto: string
  media_heat_brutto: string
  media_hot_water_brutto: string
  media_power_brutto: string
  media_water_brutto: string
  notes: ContentNote[]
  online_checkin_data: ReceptionBookingOnlineCheckInData | null
  package_vip: PackageVip | null
  postcode: string
  prices: ReceptionBookingDetailsPrices
  promocode: ApartmentBookingPromocode | null
  promotion_marketing_id: number | null
  recommender_client_display: string | null
  recommender_client_id: number | null
  requests: string
  required_climatic_tax: string
  required_deposit_amount: string
  required_payment_without_climatic_tax: string
  reservation_source: string
  residence_brutto: string
  rodo_email: boolean
  rodo_phone: boolean
  rodo_phone_call: boolean
  seller_id: number | null
  service_charge_brutto: string
  service_room_data: ReceptionBookingServiceRoomData
  skip_climatic_tax: boolean
  skip_service_charge: boolean
  smart_watches: BookingSmartWatchAssignment[]
  sms_notifications: SMSNotification[]
  sms_notifications_enabled: boolean
  source: BookingSource | null
  source_marketing_id: number | null
  street: string
  subscription_allow_change_client_data: boolean
  tags: string[]
  token: string
  total_deposit_payin: string
  tourist_vouchers: BookingTouristVoucher[]
  urls: ReceptionBookingDetailsUrls
  wallet_brutto: string
  warranty: boolean
  warranty_costs: string
}

interface ReceptionBookingClientWalletUrl {
  gastro_card_details: string
  assigment: string
}

type ReceptionBookingClientWalletVoucherKind = 'gastro_card' | 'gastro_coupon'

export type ReceptionBookingClientWalletScope = 'cafe' | 'bistro' | 'vending_machines' | 'feeding' | 'feeding_cieplice'

export interface ReceptionBookingClientWallet {
  id: number
  is_active: boolean
  name: string
  voucher_kind: ReceptionBookingClientWalletVoucherKind
  number: number
  code: string
  can_remove: boolean
  can_change_is_active: boolean
  start_amount: string
  available_amount: number
  client_used_amount: string
  group_id: number
  client: string
  scopes: ReceptionBookingClientWalletScope[]
  urls: ReceptionBookingClientWalletUrl
}

export interface ReceptionBookingWalletDetails {
  id: number
  client_wallets: ReceptionBookingClientWallet[]
}

export type FeedingTypeFilter =
  | 'all'
  | 'breakfast'
  | 'dinner'
  | 'with_feeding'
  | 'without_feeding'
  | 'breakfast_with_dinner'

export interface FakeMeterCalculation {
  water_costs: number
  water_usage: number
  heat_costs: number
  heat_usage: number
  hot_water_costs: number
  hot_water_usage: number
  power_costs: number
  power_usage: number
}

export interface SingleCalculatedFeedingPrice {
  single_price_brutto: number
  price_brutto: number
  guest_id: number
  date: string
}

export interface CalculatedFeedingPrice {
  rows: SingleCalculatedFeedingPrice[]
  total_price_brutto: string
  group_id: number
}

export interface CalculatedFeedingPriceResponseRow {
  dates: string[]
  single_price_brutto: number
  price_brutto: number
  guest_id: number
}

export interface CalculatedFeedingPriceResponse {
  rows: CalculatedFeedingPriceResponseRow[]
  total_price_brutto: string
}

export interface ReceptionWorkstation {
  id: number
  name: string
  resort_id: number
  eservice_pad_id: number
  band_reader_id: number
  tablet_id: number
}

export interface ReceptionTransferPayment {
  amount: string
  pk: number
  source: string
  source_display: string
}

export interface ReceptionTransfer {
  amount: string
  payment: ReceptionTransferPayment
}

export interface ReceptionTransferBooking {
  apartment: number
  apartment_name: string
  name: string
  id: number
  reservation_number: string
  resort: number
  resort_name: string
}

export interface ReceptionTransferDeposit {
  bookings: ReceptionTransferBooking[]
  rest_to_pay: string
  transfer_amount: string
  transfers: ReceptionTransfer[]
}

export interface WalletTransactionBillItemUrls extends BaseUrls {
  pay_by_voucher: string
}

export interface WalletTransactionBillItem {
  id: number
  booking_guest: string
  date: string
  deleted: boolean
  deleted_by: string | null
  is_paid: boolean
  is_paid_by_client_wallet: boolean
  name: string
  price: string
  source_display: string
  vat_rate: string
  unit: string
  quantity: string
  urls: WalletTransactionBillItemUrls
}

export interface WalletTransactionBill {
  id: number
  bill_id: string
  created_at: string
  guest_name: string
  source_display: string
  items: WalletTransactionBillItem[]
}

interface WalletTransactionUrls {
  print: string
  move: string
}

interface ReservationBookingPaymentUrls extends BaseUrls {
  admin: string
  synchronization_status: string
  pre_authorization_checkout: string
  deposit_return: string
  restore: string
}

export interface ReservationBookingVoucher extends BaseObj {
  created: string
  amount: string
  created_by_display: string
  description: string

  urls: BaseUrls
}

export interface ReservationBookingPayment {
  amount: string
  code_preauth: string
  created_at: string
  description: string
  hide: boolean
  id: number
  invoice_date: string
  is_removed: boolean
  last_card_numbers: string
  pin_pad_id: number | null
  source: BookingPaymentSource
  source_display: string
  type: BookingPaymentType
  type_display: string
  urls: ReservationBookingPaymentUrls
  user_display: string
  user: number
}

export interface WalletTransaction {
  created_at: string
  id: number
  available: string
  used: string
  bills: WalletTransactionBill[]
  guests: number[]
  urls: WalletTransactionUrls
}

export interface ReceptionBookingsWithAggregations {
  bookings: ReceptionBooking[]
  aggregation: ReceptionBookingsAggregationState
}

export interface ReceptionCheckoutValidationSubscription {
  booking_improvement: number
  has_rules_accepted: boolean
  subscription: SubscriptionDetails
}

export interface ReceptionCheckoutValidationResponse {
  can_checkout: boolean
  subscriptions: ReceptionCheckoutValidationSubscription[]
}
