import * as React from 'react'
import { ReceptionBookingGuestWalletLimits } from '@models/booking'
import { UncontrolledTooltip } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/utils'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  tooltipId: string
  tooltipTitle: React.ReactNode
  scopes: ReceptionBookingGuestWalletLimits[]
  amounts: string
  totalLimit: number | null | undefined
  dailyLimit: number | null | undefined
}

export const StepGuestsWalletScopesPreviewRow = ({
  title,
  amounts,
  tooltipId,
  tooltipTitle,
  scopes,
  dailyLimit,
  totalLimit,
  ...props
}: Props) => (
  <div {...props}>
    <div id={tooltipId} className="row">
      <strong className="col-2">[{amounts}]</strong>
      <div className="col-10">{title}</div>
    </div>
    <UncontrolledTooltip placement="bottom" target={tooltipId} style={{ maxWidth: 350 }} innerClassName="pt-2">
      <div className="font-11">
        {scopes.length ? (
          <>
            <div className="mb-1 text-left">{tooltipTitle}</div>

            <div className="mb-1 text-left fw-semi-bold font-11">
              Globalny limit dzienny: {dailyLimit ? formatPriceShort(dailyLimit) : 'Brak'}
            </div>
            <div className="mb-2 text-left fw-semi-bold font-11">
              Globalny limit całościowy: {totalLimit ? formatPriceShort(totalLimit) : 'Brak'}
            </div>

            {scopes.map(scope => (
              <IconWithText
                key={scope.scope}
                icon="uil-check font-14 lh-0"
                text={
                  <strong>
                    {scope.scope_display}{' '}
                    {!!scope.limit && <span className="fw-semi-bold">(limit {formatPriceShort(scope.limit)})</span>}
                  </strong>
                }
                wrapperClassNames="font-weight-bold mb-1"
                flexClassName="d-block text-left"
              />
            ))}
          </>
        ) : (
          <div className="mb-1 text-left fw-semi-bold">Dostęp nie został nadany</div>
        )}
      </div>
    </UncontrolledTooltip>
  </div>
)
