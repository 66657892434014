import * as React from 'react'
import { ReceptionBookingGuest } from '@models/booking'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import classNames from 'classnames'

interface Props {
  guest: ReceptionBookingGuest
  onStateUpdate: (booking: ReceptionBookingDetails) => void
}

export const StepGuestsWalletScopesUpdate = ({ guest, onStateUpdate }: Props): React.ReactNode => {
  const addedScopesLength = guest.wallet_limits.length

  const { action: toggleScopesStates } = useApiRequest(async () => {
    onStateUpdate(await commonObjectPost(guest.urls.wallet_change, { enable: !addedScopesLength }))
  })

  return (
    <CommonObjectConfirmAction
      role="global-scope-change-confirmation"
      handleAccept={toggleScopesStates}
      message={`Czy na pewno chcesz zmienić dostęp do zakupów dla gościa ${guest.name}?`}
    >
      <IconWithText
        wrapperClassNames="cursor-pointer"
        textClass="col-10 fw-semi-bold"
        text={<div style={{ marginLeft: -8 }}>{addedScopesLength ? 'Wyłącz wszystkie' : 'Włącz wszystkie'}</div>}
        icon={classNames('font-14 col-2 mr-0', addedScopesLength ? 'uil-times' : 'uil-check')}
      />
    </CommonObjectConfirmAction>
  )
}
