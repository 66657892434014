import * as React from 'react'
import { ReceptionBookingGuest, ReceptionBookingGuestWalletLimits } from '@models/booking'
import classNames from 'classnames'
import {
  ResortAvailableScope,
  useResortWalletGroupedScope,
} from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/use-resort-wallet-scope'
import { StepGuestsWalletScopesPreviewRow } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-preview-row'
import { StepGuestsWalletScopesUpdate } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-update'
import { ReceptionBookingDetails } from '@models/reception'

interface Props {
  walletLimits: ReceptionBookingGuestWalletLimits[]
  onStateUpdate?: (booking: ReceptionBookingDetails) => void
  guest: ReceptionBookingGuest
  resortId: number
  wrapperClassName?: string
}

export const StepGuestsWalletScopesPreview = ({
  walletLimits,
  guest,
  wrapperClassName,
  resortId,
  onStateUpdate,
}: Props): React.ReactNode => {
  const { availableShopScopes, availableGastroScopes, availableVendingMachinesScopes, availableParkAndFunScopes } =
    useResortWalletGroupedScope(resortId)

  const getScopes = React.useCallback(
    (items: ResortAvailableScope[]) =>
      items.reduce((prev, scope) => {
        const wallet = walletLimits.find(wallet => wallet.scope === scope.key)
        return wallet ? [...prev, wallet] : prev
      }, []),
    [walletLimits],
  )

  const globalLimits = walletLimits.find(walletLimit => walletLimit.scope === null)

  const shopScopes = getScopes(availableShopScopes)
  const gastroScopes = getScopes(availableGastroScopes)
  const vendingMachinesScopes = getScopes(availableVendingMachinesScopes)
  const parkAndFunScopes = getScopes(availableParkAndFunScopes)

  return (
    <div className={classNames('mt-1', wrapperClassName)}>
      {!!availableGastroScopes.length && (
        <StepGuestsWalletScopesPreviewRow
          totalLimit={globalLimits?.limit_total}
          dailyLimit={globalLimits?.limit}
          title="Punkty Gastronomiczne"
          tooltipId={`scopes-gastro-${guest.id}`}
          amounts={`${gastroScopes.length}/${availableGastroScopes.length}`}
          scopes={gastroScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Punktach Gastronomiczny:</strong>
            </span>
          }
        />
      )}

      {!!availableVendingMachinesScopes.length && (
        <StepGuestsWalletScopesPreviewRow
          totalLimit={globalLimits?.limit_total}
          dailyLimit={globalLimits?.limit}
          title="Automaty"
          tooltipId={`scopes-vending-machines-${guest.id}`}
          amounts={`${vendingMachinesScopes.length}/${availableVendingMachinesScopes.length}`}
          scopes={vendingMachinesScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Automatach:</strong>
            </span>
          }
          style={{ marginBlock: '3px' }}
        />
      )}

      {!!availableShopScopes.length && (
        <StepGuestsWalletScopesPreviewRow
          totalLimit={globalLimits?.limit_total}
          dailyLimit={globalLimits?.limit}
          title="Sklep"
          tooltipId={`scopes-shop-${guest.id}`}
          amounts={`${shopScopes.length}/${availableShopScopes.length}`}
          scopes={shopScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Sklepie:</strong>
            </span>
          }
        />
      )}

      {!!availableParkAndFunScopes.length && (
        <StepGuestsWalletScopesPreviewRow
          totalLimit={globalLimits?.limit_total}
          dailyLimit={globalLimits?.limit}
          title="Holiday Park & Fun "
          tooltipId={`scopes-parkandfun-${guest.id}`}
          amounts={`${parkAndFunScopes.length}/${availableParkAndFunScopes.length}`}
          scopes={parkAndFunScopes}
          style={{ marginBlock: '3px' }}
          tooltipTitle={
            <span>
              Dostęp do <strong>Holiday Park & Fun:</strong>
            </span>
          }
        />
      )}
      {onStateUpdate && <StepGuestsWalletScopesUpdate guest={guest} onStateUpdate={onStateUpdate} />}
    </div>
  )
}
