import * as React from 'react'
import { ReceptionBookingDetailsPrices } from '@models/reception'
import { asDecimal, formatPercentage, formatPrice } from '@helpers/utils'
import { Progress } from 'reactstrap'

interface Props {
  prices: ReceptionBookingDetailsPrices
}

export const ReservationDepositRestToPay = ({ prices }: Props): React.ReactNode => {
  const percentagePaid = asDecimal(prices.deposit_payments_total_amount)
    .div(asDecimal(prices.deposit_amount).gt(0) ? prices.deposit_amount : 1)
    .mul(100)
    .toString()

  return (
    <>
      <div className="mb-1 d-flex justify-content-between">
        <span>
          Wpłacona kaucja <strong>{formatPrice(prices.deposit_payments_total_amount)} </strong> z{' '}
          {formatPrice(prices.deposit_amount)} ({formatPercentage(percentagePaid)})
        </span>
        <span className="font-11">
          Wymagana kaucja dla tej rezerwacji: <strong className="font-12">{formatPrice(prices.deposit_amount)}</strong>
        </span>
      </div>
      <Progress value={percentagePaid} color="danger" className="mb-2" style={{ height: 10 }} />
    </>
  )
}
