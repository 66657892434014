import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { getReceptionBookingDetails, getResortDetails } from '@store/actions/reception-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AvailableReceptionBookingDetailsTab,
  ReceptionBookingDetailsTab,
  ReceptionBookingDetailsTabs,
} from '@modules/reception/details/reception-booking-details-tabs'
import { ReceptionBookingCheckInStepImprovementsList } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-list'
import { useBookingFeedingAvailability } from '@components/hooks/use-booking-feeding-availability'
import { ReceptionBookingDetailsFeeding } from '@modules/reception/details/reception-booking-details-feeding'
import { ReceptionBookingDetailsData } from '@modules/reception/details/reception-booking-details-data'
import { ReceptionBookingDetailsGuests } from '@modules/reception/details/reception-booking-details-guests'
import { ReceptionBookingDetailsDocuments } from '@modules/reception/details/reception-booking-details-documents'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingCheckInStepPaymentsContent } from '@modules/reception/checkin/step-payments/content'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { ReceptionBookingNewFeeding } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding'
import { ReceptionBookingSubscriptionImprovement } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription'

const FeedingTabs = ['feeding', 'feedingNew']

const hasFeedingImprovement = (booking: ReceptionBooking) =>
  booking.improvements.some(improvement => improvement.code.startsWith('feeding__'))

const mapToAvailableTabs = (booking: ReceptionBooking, tab: ReceptionBookingDetailsTab) =>
  FeedingTabs.includes(tab) ? (hasFeedingImprovement(booking) ? 'feeding' : 'feedingNew') : tab

interface ReceptionBookingDetailsProps extends BaseModalProps {
  booking: ReceptionBooking
  tabs?: ReceptionBookingDetailsTab[]
  initialTab?: ReceptionBookingDetailsTab
  clearOnUnmount?: boolean
}

interface FormInputs {
  language: string
}

export const ReceptionBookingDetailsModal: React.FC<ReceptionBookingDetailsProps> = ({
  booking,
  tabs = AvailableReceptionBookingDetailsTab,
  toggleIsVisible,
  initialTab = 'data',
  clearOnUnmount = true,
}) => {
  const [tab, setTab] = React.useState<ReceptionBookingDetailsTab>(mapToAvailableTabs(booking, initialTab))
  const dispatch = useAppDispatch()

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  React.useEffect(() => {
    dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id))
    dispatch(getReceptionBookingDetails(booking.urls.api_details))

    return () => {
      if (clearOnUnmount) {
        dispatch(setReceptionBookingDetails(undefined))
      }
    }
  }, [])

  const methods = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: {
      language: 'pl',
    },
  })

  const { isAvailable: hasFeeding } = useBookingFeedingAvailability(bookingDetails)

  const isNewFeeding = tab === 'feedingNew'

  const availableTabs = tabs?.map(tab => mapToAvailableTabs(booking, tab))

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Rezerwacja numer</span> <strong>#{booking.reservation_number}</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={bookingDetails}>
          {bookingDetails && (
            <>
              {(availableTabs === undefined || availableTabs?.length > 1) && (
                <ReceptionBookingDetailsTabs
                  tabs={availableTabs}
                  activeTab={tab}
                  setTab={setTab}
                  hasFeeding={hasFeeding}
                />
              )}

              {tab === 'data' && <ReceptionBookingDetailsData booking={bookingDetails} />}
              {tab === 'guests' && <ReceptionBookingDetailsGuests booking={bookingDetails} />}
              {tab === 'feeding' && <ReceptionBookingDetailsFeeding booking={bookingDetails} />}
              {tab === 'feedingNew' && (
                <ReceptionBookingNewFeeding booking={bookingDetails} onClose={toggleIsVisible} />
              )}
              {tab === 'improvements' && (
                <ReceptionBookingCheckInStepImprovementsList
                  booking={bookingDetails}
                  disabledImprovements={['hpf_ticket']}
                >
                  {improvementsAvailability => (
                    <ReceptionBookingSubscriptionImprovement
                      booking={bookingDetails}
                      improvementsAvailability={improvementsAvailability}
                    />
                  )}
                </ReceptionBookingCheckInStepImprovementsList>
              )}
              {tab === 'documents' && <ReceptionBookingDetailsDocuments booking={bookingDetails} />}
              {tab === 'payments' && <ReceptionBookingCheckInStepPaymentsContent booking={bookingDetails} />}
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      {!isNewFeeding && (
        <ModalFooter className="d-flex justify-content-between">
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>
        </ModalFooter>
      )}
    </FormProvider>
  )
}
