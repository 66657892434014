import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { GuestWalletScope, ReceptionBookingGuest, ReceptionBookingGuestWalletLimits } from '@models/booking'
import { parseISODate } from '@helpers/date-helper'
import { ReceptionCheckReminder } from '@modules/reception/common/reception-check-reminder'
import { StepGuestsGuestListRowRemove } from '@modules/reception/checkin/step-guests/step-guests-guest-list-row-remove'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { StepGuestsGuestFormDates } from '@modules/reception/checkin/step-guests/step-guests-guest-form/step-guests-guest-form-dates'
import { StepGuestsGuestFormWallet } from '@modules/reception/checkin/step-guests/step-guests-guest-form/step-guests-guest-form-wallet'
import { StepGuestsGuestFormForm } from '@modules/reception/checkin/step-guests/step-guests-guest-form/form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { differenceInYears } from 'date-fns'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import { ageOptions } from '@modules/reception/checkin/step-guests/step-guests-guest-form/consts'
import { createSelectOption } from '@helpers/utils'
import { useResortWalletScope } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/use-resort-wallet-scope'

interface Props {
  booking: ReceptionBookingDetails
  handleCancel: () => void
  guest?: ReceptionBookingGuest
  updateState: (booking: ReceptionBookingDetails) => void
  group: ReceptionBookingGroup
  isRemoveDisabled?: boolean
}

const createDefaultWalletLimits = (
  resortScopes: GuestWalletScope[],
  walletLimits: ReceptionBookingGuestWalletLimits[] | undefined,
) => {
  if (!walletLimits) return {}

  const globalScope = walletLimits.find(wallet => wallet.scope === null)

  return {
    ...resortScopes.reduce((prev, scope) => {
      const walletScope = walletLimits.find(wallet => wallet.scope === scope)
      if (!walletScope) return { ...prev, [scope]: { enabled: false, limit: null, scope } }
      return { ...prev, [scope]: { ...walletScope, enabled: true } }
    }, {}),
    ...(globalScope && { global: { ...globalScope } }),
  }
}

export const StepGuestsGuestForm = React.forwardRef<HTMLDivElement, Props>(
  ({ booking, handleCancel, guest, updateState, group, isRemoveDisabled }, ref) => {
    const { addSuccessNotification } = useNotificationHook()
    const availableScopes = useResortWalletScope(booking.resort_id)

    const groupOptions = booking.groups.map((group: ReceptionBookingGroup) => createSelectOption(group.name, group.id))

    const methods = useForm<StepGuestsGuestFormInputs>({
      defaultValues: {
        date_from: parseISODate(guest?.date_from),
        date_to: parseISODate(guest?.date_to),
        name: guest?.name || '',
        email: guest?.email || '',
        phone: guest?.phone || '',
        type: guest?.type !== 'adult' ? ageOptions[1] : ageOptions[0],
        groupOption: makeDefaultSelectOption(groupOptions, group.id, groupOptions[0]),
        wallet_limits: createDefaultWalletLimits(availableScopes, guest?.wallet_limits),
        birthday: parseISODate(guest?.birthday) || undefined,
      },
      mode: 'all',
    })

    const { watch, getValues, setError } = methods

    const selectType: CustomReactSelectOption = watch('type')
    const birthday = watch('birthday')

    useDidUpdateEffect(() => {
      if (!birthday) return

      const isAdult = differenceInYears(parseISODate(booking.date_from) || 0, birthday) < 18

      if (isAdult) {
        methods.setValue('type', ageOptions[1])
        return
      }

      methods.setValue('type', ageOptions[0])
      methods.setValue('birthday', undefined)
    }, [birthday])

    const { isLoading, action: handleSubmit } = useFormRequest(
      async (event?: React.MouseEvent) => {
        methods.clearErrors()
        event?.preventDefault()

        const values = getValues()

        const { global, ...scopeWallets } = values.wallet_limits

        const payload = {
          ...values,
          name: values.name,
          group_id: values.groupOption?.value,
          wallet_limits: [...Object.values(scopeWallets).filter(wallet => wallet.enabled), { ...global, scope: null }],
          type: values.type.value,
          birthday: values.type.value !== 'adult' ? values.birthday : null,
          email: values.type.value === 'adult' ? values.email : '',
          phone: values.type.value === 'adult' ? values.phone : '',
        }

        updateState(
          guest?.id
            ? await commonObjectPut<ReceptionBookingDetails>(guest.urls.details, payload)
            : await commonObjectPost<ReceptionBookingDetails>(booking.urls.check_in_step_guest, payload),
        )

        addSuccessNotification(
          guest?.id
            ? `Dane gościa ${values.name} zostały zmienione`
            : `Gość ${values.name} został dodany do rezerwacji`,
        )

        handleCancel()
      },
      setError,
      { nonFieldErrorsAs: 'wallet_limits.global.limit' },
    )

    const saveButtonLabel = guest ? 'Zapisz dane' : <IconWithText icon="uil-plus" text="Dodaj gościa" />

    return (
      <div className="py-2 reception__booking-check-in__guest-form bg-grey-light" ref={ref}>
        <FormProvider {...methods}>
          <h4 className="text-dark my-2">{!guest ? 'Dodaj osobę do grupy:' : 'Edytuj gościa:'}</h4>
          <Row>
            <StepGuestsGuestFormForm groupOptions={groupOptions} />
            <StepGuestsGuestFormDates booking={booking} />
          </Row>

          <StepGuestsGuestFormWallet guest={guest} resortId={booking.resort_id} />

          {selectType?.value === 'adult' && guest && (
            <Row>
              <Col md={12}>
                {(!guest.rodo_email || !guest.rodo_phone) && (
                  <ReceptionCheckReminder initialValue={false} id="rodo" errors={{}} type="success" required={true}>
                    <strong>Pamiętaj</strong>, aby poinformować gości o darmowym drinku / koktajlu owocowym <br /> jeśli
                    potwierdzą adres e-mail i zaznaczą wszystkie zgody marketingowe.
                  </ReceptionCheckReminder>
                )}
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12} className="text-center mt-1">
              {guest && (
                <StepGuestsGuestListRowRemove
                  updateState={updateState}
                  guest={guest}
                  handleComplete={handleCancel}
                  disabled={isRemoveDisabled}
                >
                  <Button outline color="danger" className="mr-2" type="button" disabled={isRemoveDisabled}>
                    Usuń
                  </Button>
                </StepGuestsGuestListRowRemove>
              )}
              <Button color="light" className="mr-2" onClick={handleCancel}>
                Anuluj
              </Button>
              <SaveButton
                type="button"
                onClick={handleSubmit}
                isSaving={isLoading}
                role="save-guest-button"
                label={saveButtonLabel}
              />
            </Col>
          </Row>
        </FormProvider>
      </div>
    )
  },
)
