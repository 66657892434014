import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionBookingDetails } from '@models/reception'
import { NotificationEvents } from '@models/dashboard'
import { Apartment } from '@models/apartment'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { getReceptionBookingDetails, updateReceptionBookingDetails } from '@store/actions/reception-actions'
import {
  getBookingPayments,
  getBookingVouchers,
  getBookingWalletDetails,
  getWalletTransactions,
} from '@store/actions/reservation-actions'

export const ReservationDetailsWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()
  const details = useAppSelector((state: RootState) => state.receptionState.receptionBookingDetails)

  useWebSocket<ReceptionBookingDetails | Apartment>(
    async (message, payload) => {
      switch (message) {
        case NotificationEvents.BOOKING_CHANGE: {
          const booking = payload as ReceptionBookingDetails
          if (booking.id === details?.id) {
            await dispatch(getReceptionBookingDetails(booking.urls.api_details))
            await dispatch(getBookingPayments(booking))
            await dispatch(getWalletTransactions(booking))
            await dispatch(getBookingWalletDetails(booking))
            await dispatch(getBookingVouchers(booking))
          }
          break
        }
        case NotificationEvents.APARTMENT_CHANGE: {
          const apartmentDetails = payload as Apartment
          if (details?.apartment.id === apartmentDetails.id) {
            dispatch(updateReceptionBookingDetails({ ...details, apartment: apartmentDetails }))
          }
          break
        }
      }
    },
    [],
    [NotificationEvents.BOOKING_CHANGE, NotificationEvents.APARTMENT_CHANGE],
  )

  return null
}
