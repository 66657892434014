import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'

interface Props {
  status: 'initial' | 'confirmed' | 'checked-in' | 'finished'
  date: string | null
  booking: ReceptionBookingDetails
  isActive: boolean
}

export const ReservationStatusLabel = ({ status, date, booking, isActive }: Props): React.ReactNode => {
  const isConfirmed = status === 'checked-in' && booking.status === 'confirmed'

  const statusUpperCaseFirstCharacter = isConfirmed
    ? 'Zameldowana'
    : booking.status_display.charAt(0).toUpperCase() + booking.status_display.slice(1)

  return (
    <div className={classNames('reservation-details__label', { 'is-active': isActive }, `is-${status}`)}>
      {isActive && (
        <>
          <span className="font-14 fw-semi-bold d-block mr-2">{statusUpperCaseFirstCharacter}</span>
          {date && <span className="font-10 flex-grow-1">od {toDefaultDateTimeFormat(date)}</span>}
          {booking.extended_stay && <div className="reservation-details__label__extended" />}
          {!!booking.package_vip && (
            <span className="reservation-details__label__vip" data-testid="vip-mark">
              <img src={require('@assets/images/vip-white.svg')} alt="vip" height="24" />
            </span>
          )}
        </>
      )}
    </div>
  )
}
