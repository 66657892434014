import * as React from 'react'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { IconWithText } from '@components/icon-with-text'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { parseISODate, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails } from '@models/reception'
import { isBookingInitial } from '@modules/reservations/details/utils'
import { isSameDay } from 'date-fns'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataAutoCancel = ({ booking }: Props): JSX.Element => {
  const [showAutoCancelChangeModal] = useModal('ReservationDisableAutoCancelModal', { booking })

  const isAutoCancelChanged =
    booking.disable_auto_cancel_date &&
    booking.auto_cancel_after &&
    !isSameDay(parseISODate(booking.disable_auto_cancel_date) as Date, parseISODate(booking.auto_cancel_after) as Date)

  const isAutoCancelDisabled = booking.disable_auto_cancel && !booking.disable_auto_cancel_date

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-times-square font-15" text="Auto-anulowanie rezerwacji:" />}
      className="border-bottom pb-2 mr-3"
    >
      {isBookingInitial(booking) ? (
        <div className="d-flex align-items-center">
          {isAutoCancelChanged && (
            <BadgeWithIcon icon="uil-hourglass" title="zmienione" variant="warning" className="mr-2" />
          )}
          {isAutoCancelDisabled && (
            <BadgeWithIcon icon="uil-times" title="wyłączone" variant="light" className="text-muted" />
          )}
          <span className="font-11">
            {!isAutoCancelDisabled &&
              toDefaultDateTimeFormat(
                booking.disable_auto_cancel_date ? booking.disable_auto_cancel_date : booking.auto_cancel_after,
              )}

            <button
              className="uil-stopwatch bg-transparent p-0 border-0 text-default ml-1 font-14"
              onClick={showAutoCancelChangeModal}
            />
          </span>
        </div>
      ) : (
        <>
          <BadgeWithIcon
            icon="uil-times"
            title="Opcja niedostępna"
            variant="light"
            className="text-muted cursor-default"
            id={`auto-cancel-disabled-${booking.id}`}
          />

          <UncontrolledTooltip
            placement="top"
            target={`auto-cancel-disabled-${booking.id}`}
            className="p-1"
            style={{ maxWidth: 300 }}
          >
            <p className="font-11 text-left mb-0">Niedostępne w tym statusie rezerwacji </p>
          </UncontrolledTooltip>
        </>
      )}
    </ReservationDataRow>
  )
}
