import * as React from 'react'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { formatPrice } from '@helpers/utils'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { ProductCardTitle } from '@components/products/product-card-title'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { PackageDetailsRow } from '@modules/package/details/information/row'

interface GastroCardCommonProps {
  packageVipDetails: PackageVipDetails
}

export const PackageVipCommon: React.FC<GastroCardCommonProps> = ({ packageVipDetails }) => {
  const { addSuccessMessage } = useNotificationHook()
  const sources = useAppSelector((state: RootState) => state.reportsState.appData?.sources) || []
  const quick_search_url = useAppSelector((state: RootState) => state.appState.appData.urls.dashboard.quick_search_url)

  const [handleToggleDialog] = useModal('PackageVipDialog', {
    packageVipClient: packageVipDetails.client,
    packageVip: { ...packageVipDetails, seller: packageVipDetails.seller?.id },
  })

  const dispatch = useAppDispatch()

  const { isLoading, action: handleInvoice } = useApiRequest(async () => {
    const data = await commonObjectPost<PackageVipDetails>(packageVipDetails.urls.generate_invoice)
    dispatch(updatePackageVipDetails(data))
    addSuccessMessage('Sukces', `Faktura została wygenerowana, numer faktury ${data.invoice_number}`)
  })

  const [badgeClass, badgeIcon] = getStatusClassIcon(packageVipDetails.status)

  const sourceMarketing = React.useMemo(
    () => sources.find(row => row.id === packageVipDetails.source_marketing)?.name || '',
    [sources, packageVipDetails.client?.profile],
  )

  return (
    <>
      <ProductCardTitle
        title="Szczegóły pakietu VIP"
        adminUrl={packageVipDetails.urls.admin}
        isEditAllowed={!packageVipDetails.is_paid}
        onEdit={handleToggleDialog}
      />

      <div className="table-responsive">
        <table className="table table-sm">
          <colgroup>
            <col width="40%" />
            <col width="60%" />
          </colgroup>
          <tbody>
            <PackageDetailsRow label="Status">
              <BadgeWithIcon icon={badgeIcon} title={packageVipDetails.status_display} className={badgeClass} />
            </PackageDetailsRow>

            <PackageDetailsRow label="Cena brutto">{formatPrice(packageVipDetails.price_brutto)}</PackageDetailsRow>
            <PackageDetailsRow label="Pozostało do zapłaty">
              {formatPrice(packageVipDetails.required_payment)}
            </PackageDetailsRow>
            <PackageDetailsRow label="Data ważności">
              {toDefaultDateTimeFormat(packageVipDetails.expire_after)}
            </PackageDetailsRow>
            <PackageDetailsRow label="Data sprzedaży">
              {toDefaultDateFormat(packageVipDetails.sell_date)}
            </PackageDetailsRow>
            <PackageDetailsRow label="Źródło">{sourceMarketing}</PackageDetailsRow>
            <PackageDetailsRow label="Sprzedawca">
              {packageVipDetails.seller?.name || 'Nieprzypisany'}
            </PackageDetailsRow>
            <PackageDetailsRow label="Numer faktury">
              <>
                {packageVipDetails.invoice_number ? (
                  <a href={`${quick_search_url}?q=${packageVipDetails.invoice_number}`}>
                    <strong>
                      {packageVipDetails.invoice_number} ({packageVipDetails.invoice_status_display})
                    </strong>
                  </a>
                ) : (
                  '-'
                )}
                {packageVipDetails.invoice_status === 'proforma_invoice' && packageVipDetails.is_paid && (
                  <SaveButton
                    onClick={handleInvoice}
                    isSaving={isLoading}
                    label="Wygeneruj"
                    className="btn-primary btn btn-xs"
                    labelSaving="Generowanie.."
                  />
                )}
              </>
            </PackageDetailsRow>

            <PackageDetailsRow label="Rodzaj">{packageVipDetails.type_display}</PackageDetailsRow>

            <PackageDetailsRow label="Data wymaganej płatności">
              {packageVipDetails.required_payment_date
                ? toDefaultDateFormat(packageVipDetails.required_payment_date)
                : 'Brak'}
            </PackageDetailsRow>

            <PackageDetailsRow label="Auto anulowanie po">
              {packageVipDetails.auto_cancel_after_date
                ? toDefaultDateFormat(packageVipDetails.auto_cancel_after_date)
                : 'Brak'}
            </PackageDetailsRow>
          </tbody>
        </table>
      </div>
    </>
  )
}
