import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import classnames from 'classnames'

interface Props {
  children: React.ReactNode
  handleAccept?: (message?: string) => void
  isLoading?: boolean
  title?: string
  message?: React.ReactNode
  askForReason?: boolean
  disabled?: boolean
  as?: any
  defaultReason?: string
  headerClassName?: string
  buttonAcceptClassName?: string
  submitLabel?: React.ReactNode
  role?: string
}

export const CommonObjectConfirmAction = ({
  children,
  handleAccept,
  isLoading,
  defaultReason,
  askForReason = false,
  title = 'Potwierdź operacje',
  message = 'Czy na pewno chcesz wykonać wybraną akcję? Operacja ta jest nieodwołalna',
  disabled,
  as = 'span',
  buttonAcceptClassName,
  headerClassName,
  submitLabel,
  role = 'common-object-confirmation',
}: Props): JSX.Element => {
  const [showModal, hideModal] = useModal('ConfirmModal', {}, { persist: true })

  const As = as

  const onAccept = async (reason: string) => {
    if (handleAccept) {
      await handleAccept(reason)
    }
    hideModal()
  }

  if (!handleAccept) {
    return <span>{children}</span>
  }

  const handleToggleConfirmOpen = e => {
    e.preventDefault()
    e.stopPropagation()

    showModal(
      null,
      {
        title,
        askForReason,
        showLoading: isLoading,
        onAccept,
        onCancel: hideModal,
        message,
        defaultReason,
        buttonAcceptClassName,
        headerClassName,
        submitLabel,
      },
      true,
    )
  }

  return (
    <As
      role={role}
      onClick={disabled ? undefined : handleToggleConfirmOpen}
      className={classnames({ 'pe-none opacity-5': disabled })}
    >
      {children}
    </As>
  )
}
